import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { getName } from "../helpers/utils";
import { Location } from "@gatsbyjs/reach-router";

const PageTemplateRedirect = ({ pageContext, location }) => {
  const data = pageContext.errorCode;
  useEffect(() => {
    const name = getName(location.pathname.split("/")[1]);
    navigate(`/${name}/errors`, {
      state: { data }
    });
    //eslint-disable-next-line
  }, []);

  return <></>;
};

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <PageTemplateRedirect {...locationProps} {...props} />}
  </Location>
);
